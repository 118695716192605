.pagination {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;

    li {
        display: inline;
    }

    .page-link {
        border: 1px solid $color-font;
        border-radius: 80px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0 8px;
        text-decoration: none;

        &:hover {
            @extend .btn-primary;
        }
    }

    .disabled .page-link {
        cursor: not-allowed;
    }
}



body {
  background: $color-red-light;
  color: $color-font;
  font-family: 'Overpass', sans-serif;
  font-size: 20px;
}

h1 {
    font-weight:700;
    font-size: 3rem;
   
}

.text-center { text-align: center; }
.text-right { text-align: right; }

.uppercase { text-transform: uppercase; }

.text-sm { font-size: 0.875rem; }
.text-base { font-size: 1rem; }
.text-xl { font-size: 1.25rem; }
.text-2xl { font-size: 1.5rem; }

.text-pink { color: $color-red; }
.text-black { color: #000; }

.bg-lightblue { background-color: $color-blue-light; }

.underline { text-decoration: underline; }
.no-underline { text-decoration: none; }
.italic { font-style: italic; }

.font-normal { font-weight: 400;}
.font-semibold { font-weight: 600;}
.font-bold { font-weight: 700;}

.break-words { overflow-wrap: break-word; }
.break-all { word-break: break-all; }

.tracking-wide { letter-spacing: 0.025rem; }

.list-none { list-style-type: none;}

.max-w-600 { max-width: 600px; }
.max-w-800 { max-width: 800px; }
.mx-auto { margin-right: auto; margin-left: auto;}

.d-block { display: block; }
.d-inline-block { display: inline-block; }

.icon-20 {
  width: 20px;
  height: 20px;
  text-align:center;
}

@each $step in map-keys( $spaces ) {
  @for $i from 0 through 10 {
    $directions: map-get($spaces, $step);
    @if (type-of($directions) != 'map') {
      $directions: ( $directions );
    }
    .p#{$step}-#{$i} {
      @each $direction in $directions {
        padding-#{$direction}: $space*$i !important;
      }
    }
    .m#{$step}-#{$i} {
      @each $direction in $directions {
        margin-#{$direction}: $space*$i !important;
      }
    }
  }
}



@media (max-width: 576px) {
  @each $step in map-keys( $spaces ) {
    @for $i from 0 through 10 {
      $directions: map-get($spaces, $step);
      @if (type-of($directions) != 'map') {
        $directions: ( $directions );
      }
      .sm-p#{$step}-#{$i} {
        @each $direction in $directions {
          padding-#{$direction}: $space*$i !important;
        }
      }
      .sm-m#{$step}-#{$i} {
        @each $direction in $directions {
          margin-#{$direction}: $space*$i !important;
        }
      }
    }
  }
  

}
.form-control,
.input,.textarea {
    background: transparent;
    outline:none !important;
    border: 2px solid $color-font;
    border-radius: 20px;
    height:36px;
    line-height:36px;
    padding:3px 22px;
    width: 100%;
    box-sizing: border-box;

    &[disabled] {
        border: 0;
    }

    &::placeholder {
        color: $color-font-disabled;
        font-style: italic;
    }

}

label {
    margin-left: 10px;
    margin-bottom: 8px;
    display: block;
    font-weight: 600;
}
.custom-file {
    input {
        border: none;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    label {
        background-color: $color-blue;
        display: inline-block;
        border-radius: 20px;
        height:36px;
        padding: 0 22px;
        color: $color-red-light;
        line-height: 36px;
        font-weight: 500;
    }

    
}
.form-control + .form-control {
    //margin-top: 15px;  - Not needed?
}

.form-group {
    margin-bottom: 20px;

    &.error label {
        color: $color-red-dark;   
    }
    .invalid-feedback {
        color: $color-red-dark;
        font-size: 80%;
        margin-top: 8px;
        padding: 3px 12px;
    }
}
.form-group > :last-child {
    margin-bottom:15px;
}

.form-text {
    margin-top: 8px;
    padding: 3px 12px;
    display: inline-block;
}


.textarea {
    height:auto;
}

.checkbox {
    label {
        font-weight: 500;
    }
}

input[type=checkbox] {
    opacity: 0;

}

.form-check label::before{
    content: "";
    display: inline-block;
    
    height: 22px;
    width: 22px;
    
    border: 2px solid #222;
    border-radius: 6px;
}
.form-check label::after {
    content: "";
    display: inline-block;
    height: 10px;
    width: 26px;
    border-left: 5px solid;
    border-bottom: 5px solid;
    color: $color-red;
    
    transform: rotate(-45deg);
}

.form-check label {
    position: relative;
    left: 35px;
    font-weight: 500;
    line-height: 32px;
    width: calc(100% - 35px);
}
.form-check label::before,
.form-check label::after {
    position: absolute;
}
/*Outer-box*/
.form-check label::before {
    top: 3px;
    left: -35px;
}
/*Checkmark*/
.form-check label::after {
    left: -33px;
    top: 0px;
}
/*Hide the checkmark by default*/
.form-check input[type="checkbox"] + label::after {
    content: none;
}
/*Unhide the checkmark on the checked state*/
.form-check input[type="checkbox"]:checked + label::after {
    content: "";
}

.input-group {
    display: flex;
    flex-direction: row;
    border:1.5px solid #222;
    border-radius: 15px;
    padding-left: 22px;
    padding-right: 1px;

    .input-group-addon {
        padding-top: 4px;
        padding-right: 1px;
        background-color: $color-red-light;
        flex-grow: 0;
        flex-shrink: 0;
        height: 30px;
    }


    .form-control {
        border: 0;
        padding: 0;
    }
}

.input-group-btn {
    display: flex;
    flex-direction: row;

    .btn {
        border: none;
        border-left: 2px solid #222;
        border-bottom: none;
        background-color: $color-red-light;
        height: 35px;
        outline: none;
        &:first-child {
            border-radius: 0;
        }

        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.search-form {
    input {
        width: calc(100% - 44px);
    }
}
#div_id_country, #div_id_vat_rate, #div_id_language_preference {
    > div {
        border: 2px solid $color-font;
        border-radius: 20px;
        height:36px;
        line-height:36px;
        select {
            outline:none !important;
            border: none !important;
            margin-left: 10px;
            width: calc(100% - 20px);
        }
    }
}

#div_id_start_time, #div_id_end_time, #div_id_start_selling_at, #div_id_stop_selling_at {
    > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        input {
            width: 45%;
            text-align: center;
        }
    }
    
}
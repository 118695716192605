.btn{
    display:inline-block;
    margin:0 auto;
    text-align:center;
    border-radius:80px;
    padding:8px 43px;
    // text-transform:uppercase;
    letter-spacing: .1em;
    font-size:.9em;
    border: 2px solid $color-blue;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }

    &.btn-sm {
        padding: 4px 15px;
    }
}


.btn-primary{
    background: $color-blue;
    color: $color-font-invert;
}

.btn-danger {
    border-color: $color-red;
    background: $color-red-light;
}

.submit-button {
    margin:30px auto;
    text-align:center;
    text-transform:none;
}


@media (max-width: 992px) {

    .btn {
        padding:8px 30px;
    }
}

.copyright {
    height:30px;
    text-align:center;
    font-size:12px;
    color:$color-red;
    p{
        margin:0;
        padding:0;
    }
}

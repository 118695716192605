@import url('https://fonts.googleapis.com/css?family=Lato|Overpass:400,600,700');

@import "settings.scss";

@import "normalize.scss";
@import "container.scss";
@import "utils.scss";

@import "header.scss";

@import "boilerplate.scss";


@import "btn.scss";

@import "reviews.scss";

@import "form.scss";
@import "copyright.scss";
@import "pagination.scss";
    
@import "tickset.scss";


$color-red-light: #f6f6f6;
$color-red: #FE8282;
$color-red-dark: #FF5B4B;
$color-font: #04040b;
$color-font-disabled: #818181;

$color-font-invert: #fbeaeb;
$color-font-white: #fff;

$color-blue: #0E0A40;
$color-blue2: #312783;
$color-blue3: #CBD3E8;
$color-blue4: #0d0940;

$color-blue-light: #cad4e8;

$gutter: 32px;
$space: $gutter/4;
$spaces: (
  t: 'top',
  r: 'right',
  b: 'bottom',
  l: 'left',
  y: (
    'top',
    'bottom',
  ),
  x: (
    'left',
    'right',
  ),
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;


.fullpage {
    min-height:100vh;
}

.start {
    background: url(../img/bg-purple-quarter.svg);
    background-size: cover;
    background-position: 50% 50%;
}
body.purple {
    background-image: url(../img/bg-purple.svg);
    background-position: 50% -10%;
    background-repeat: no-repeat;

    .container {
        .max-w-800 {
            background-color: $color-red-light;
        }
    }
}
body.purple-footer, body.purple {

    .footer {
        .left {
            background-image: url(../img/tickset_symbol_purple.svg);
            background-repeat: no-repeat;
            background-size: 68px;
            background-position-x: 50%;
            img {
                visibility: hidden;
            }
        }
    }
}
.link-block {
    display:block;
    text-align:center;
    color:$color-blue;
    text-decoration:none;
    font-size:16px;
}


.acceptterms {
    text-align:left;
    margin-top:30px;
}

.block {
    padding: 80px 30px;
    text-align:center;

    ul{
        list-style:none;
        margin:0;
        padding:0;
        opacity:.8;
        li{
            list-style:none;
            margin:0;
            padding:0;
        }
    }
    h1 {
        color: $color-font-white;
    }
    &.light {
        h1 { color: $color-font; }
    }

    p{
        color: $color-font-white;
    }
    h3{
        margin-top:0;
    }
}

.red-bg {
    background: $color-red;
    color: $color-font-white;

    h2 {
        font-family: 'Lato';
        font-size: 2.4rem;
    }
}

.blue-bg {
    background: $color-blue2;
    color: $color-font-white;

}

.darkblue-bg {
    background: $color-blue4;
    color: $color-font-white;

}

.block-feature {
    padding:0 30px 60px;
    text-align:center;
    max-width:900px;
    margin:0 auto;
}

.feature-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    
    .feature {
        width: 30%;
        margin-bottom: 4rem;

        .icon {
            
            height: 150px;
            padding: 40px 20px;
            img {
                width: 100%;
                max-width: 120px;
                height: 100%;
            }
        }

    }
}

.avatar {
    width:150px;
    height:150px;
    background:#ee8b88;
    margin-bottom:20px;

}
.circle {
    border-radius:50% 50%;
}

.contacts {
    display: flex;
    margin: 0 auto;
    flex-flow: row wrap;
    justify-content: space-around;
    max-width: 900px;
    .contact {
        width: 260px;
    }
}

.block-txt {
    text-align:center;
    margin:0 auto;
    max-width:600px;
    margin-bottom:8px;
    padding:0 30px;
    p {
        line-height: 2rem;
        font-size: 1.25rem;
    }
}

a {
    color: inherit;
}


.table {

    &.table-responsive {
        width: 100%;
        max-width: 100%;
    }


    
    tbody > tr {
        border-bottom: 1px solid $color-font;
    }

    td {
        padding: $gutter 0;
        vertical-align: top;
    }

    th + th,
    td + td {
        padding-left: $gutter;
    }
    .total-row {border-bottom: none;}
}

hr {
    &.dark-line {
        width: 100% !important;
        border-color: $color-font;
    }

}

dt {
    font-weight: bold;
    margin-bottom: 4px;
}

dd {
    margin: 0;
}

dd + dt {
    margin-top: 0.5em;
}

.content {
    max-width: 1000px;
    h1 {
        margin-left: 20px; 
        margin-right: 20px;
    }

    h2 { margin-left: 60px; margin-right: 60px; }
    p, ul, ol { 
        margin-left: 100px; 
        margin-right: 100px; 
    }
    li, p {
        font-family: 'Lato';
        line-height: 2rem;

    }
}
.content-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width:1200px;
    font-size:1.5rem;
    text-align:center;
    p{
        text-align:left;
        padding-bottom:.4em;
    }
    h1{
        text-align:left;
        margin-bottom:.4em;
        font-size:3rem;
        font-weight: 700;
    }

    .btn {
        font-size:1.5rem;
    }
}

.btn-center {
    margin: 0 auto;
    display: block !important;
}
.footer {
    
    .left {
        display: flex;
        align-items: center;

        img {
            width:180px;
            height:68px;
        }
    }
    .logos {
        max-height: 100px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        .logo {
            width: 8%;
            min-width: 50px;
            padding: 1%;
            &.double {
                width: 20%;
                min-width: 140px;
            }
        }
    }
}

.form-logos {
    max-height: 100px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    .logo {
        width: 8%;
        min-width: 50px;
        padding: 1%;
        &.double {
            width: 20%;
            min-width: 140px;
        }
    }
}


.no-hamburger {
    #nav-icon, .header-menu {
        display: none;
    }
}
@media  (max-width: 992px) {
    .content-center {
        width:90%;
        font-size:1em;
        p{
            text-align:center;
        }
        h1{
            font-size:1.8rem;
            text-align:center;
        }
    }
}

@media  (max-width: 768px) {

    .content-center { 
        background-color: $color-red-light; 
        width: 100%;
        p { padding: 0 20px; }
        .btn {
            font-size: 1rem;
        }
    }

    .footer {
        .row > div {
            text-align: center;
            justify-content: center;
            margin-bottom: 20px;
        }

        .logos {
            max-height: none;
            img {
                max-height: 120px;
            }
            
        }
    }
    .feature-wrap {
        .feature {
            width: 40%;
        }

    }

    .form-logos {
        max-height: none;
        
    }
    .hscroll {
        overflow-x: auto;
    }
    


}

@media (max-width: 576px) {


    .content {
        h2 { margin-left: 20px; margin-right: 20px; }
        p, ul, ol { 
            margin-left: 20px; 
            margin-right: 20px; 
        }
        li, p {
            font-family: 'Lato';
            line-height: 2rem;
    
        }
    }
    .feature-wrap {
        .feature {
            width: 80%;
        }
    }

}
.blockwrap {
    max-width: 1200px;
    margin: 0 auto;
 .quote {
    width: 33%;
    display: inline-block;
    vertical-align: top;

 .quoten {
        min-height: 100px;
        margin: 30px 7px 0 0;
        padding: 20px;
        position: relative;
        color: #999;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 6px 40px rgba(0,0,0,0.08);
        &:before {
            width: 0;
            height: 0;
            position: absolute;
            bottom: -16px;
            left: 25px;
            content: "";
            border: 8px solid;
            border-color: #fff #fff transparent transparent;
        }
    }
    .txtn{
        padding: 15px 0 0 15px;
        display: inline-block;
        color: #999;
        line-height: 18px;
        width: 100%;
        text-align: left;
        strong {
            display: block;
        }
    }
    img {
        width: 60px;
        height:60px;
        
        margin: 30px 0 0 10px;
        float: left;
        background: #ee8b88;
        border-radius:50%;
    }
 }   


}


@media  (max-width: 992px) {
    .blockwrap {
        .quote {
           width: 100%;
        }
    }

}
.topbanner {
  background: $color-blue4;
  color: $color-font-white;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 1rem;
  line-height: 2.5rem;
}
.logo-wrapper {
  float: left;

  h1 {
    display: none
  }
}

.header {
  position: absolute;
  width: 100%;
  padding: 60px 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;

}

.logo {
  width: 200px;
}

.header-menu {
  display: none;
  margin: 0;
  padding: 0;
  float: right;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      float: left;

      a {
        display: block;
        text-transform: uppercase;
        color: $color-font;
        text-decoration: none;
        margin: 0 15px;
        font-weight: 600;
        padding: 7px;
        letter-spacing: 1px;

      }
    }
  }

}

#overlay {
  display: none;
}

#overlay.overlay-open {
  display: block;

  position: fixed;
  background: rgba(0, 0, 0, .3);
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
}


#nav-icon {
  float: right;
  width: 40px;
  height: 24px;
  position: relative;
  margin: 0 auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  display: block;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 4px;
  width: 50%;
  background: #222;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon span:nth-child(1),
#nav-icon span:nth-child(2) {
  top: 0px;
}

#nav-icon span:nth-child(3),
#nav-icon span:nth-child(4) {
  top: 10px;
}

#nav-icon span:nth-child(5),
#nav-icon span:nth-child(6) {
  top: 20px;
}

#nav-icon.open span:nth-child(1),
#nav-icon.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(2),
#nav-icon.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(1) {
  left: 6px;
  top: 5px;
}

#nav-icon.open span:nth-child(2) {
  left: calc(50% -6px);
  top: 5px;
}

#nav-icon.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon.open span:nth-child(5) {
  left: 6px;
  top: 18px;
}

#nav-icon.open span:nth-child(6) {
  left: calc(50% -6px);
  top: 18px;
}


.nav-open {
  .header {
    position: fixed;
    background: $color-red-light;
    z-index: 9999;
    -webkit-box-shadow: 0 3px 3px -3px rgba(0, 0, 0, .1);
    box-shadow: 0 3px 3px -3px rgba(0, 0, 0, .1);
    

    .header-menu {
      float: none;
      padding-top: 60px;
      padding-bottom: 30px;
      display: block !important;
      margin-left: 20px;
    }

    ul {
      display: block;

      &:after,
      &:before {
        content: '';
        clear: both;
      }

      li {
        float: none;
        display: block;

        a {
          display: block;
          margin: 0;
        }
      }
    }
  }
}


@media (max-width: 768px) {

  .nav-open {
    .header {
      position: fixed;
      background: $color-red-light;
      z-index: 9999;
      -webkit-box-shadow: 0 3px 3px -3px rgba(0, 0, 0, .1);
      box-shadow: 0 3px 3px -3px rgba(0, 0, 0, .1);
      
  
      .header-menu {
        float: none;
        padding-top: 60px;
        padding-bottom: 30px;
        display: block !important;
      }
  
      ul {
        display: block;
  
        &:after,
        &:before {
          content: '';
          clear: both;
        }
  
        li {
          float: none;
          display: block;
  
          a {
            display: block;
            margin: 0;
          }
        }
      }
    }
  }
  


}
@media (min-width: 768px) {

  #nav-icon {
    display: none;
  }

  .header-menu {
    display: block;

  }
}

@media (max-width: 576px) {

  .logo {
    width: 120px;
  }

}
.container{
    @extend .clearfix;
    margin:auto auto;
    text-align:left;
    width:100%;
    max-width:1200px;
}


.containerflex{
    @extend .clearfix;
    text-align:left;
    margin: 0 20px;
    width: auto;
}

@media (min-width: map-get($grid-breakpoints, 'xs')) {
    .containerflex {
        margin:0 auto;
        width:80%;
    }
}

.row {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: $gutter;
}

@each $infix in map-keys($grid-breakpoints) {
    @if map-get($grid-breakpoints, $infix) > 0 {
        @for $i from 1 through 12 {
            .col-#{$infix}-#{$i} {
                grid-column-end: span 12;
            }
        }
        @media (min-width: map-get($grid-breakpoints, $infix)) {
            @for $i from 1 through 12 {
                .col-#{$infix}-#{$i} {
                    grid-column-end: span $i;
                }
            }
        }
    } @else {
        @for $i from 1 through 12 {
            .col-#{$infix}-#{$i} {
                grid-column-end: span $i;
            }
        }
    }
}
